import { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { handleAddItemToCart } from "../../../actions/shoppingCart";
import handleAlert from "../../../helpers/handleAlert";
import { studyInCart } from "../../../helpers/cart";
import axios from "axios";
import {
  BoxTitle,
  PanelContent,
  ContainerPricing,
  BoxPagoSucursal,
  PriceLinea,
  BoxBgBottom,
  CustomBox,
  BtnPagarAhora,
  PriceDetails,
  BoxPadding,
  Paragraph,
} from "./styles/StylesBannerPaneles";

const imageDesktop =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2FPanelBanners%2FImagenH.png?alt=media&token=a91a6c25-e734-44a6-8db3-23df2d9398e1";

const imageMobile =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2FPanelBanners%2FImagenHmob.png?alt=media&token=a2498da1-05c4-4a46-8bde-ceb808096b58";

const bgImageDesk =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2FPanelBanners%2FVectorH.png?alt=media&token=08243aa0-0dab-413c-b21d-748afd5fd1db";

const bgImage =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2FPanelBanners%2FVectorHmob.png?alt=media&token=cf45593a-e668-4ec3-8185-b7b5f6055ed2";

const BgNewService =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2FPanelBanners%2FVectorSer1.png?alt=media&token=c987391c-0018-49c6-be8f-87d4cf26ee10";

const bgImageBottom =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2FPanelBanners%2FVectorBot1.png?alt=media&token=804d7636-e355-462d-a680-b45a18f216b6";

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(to right, #5097e3, #0cac8c, #30df99);
  height: 640px;

  @media (min-width: 500px) {
    max-width: 36rem;
    height: 740px;
  }

  @media (min-width: 768px) {
    max-width: 36rem;
    height: 740px;
  }

  @media (min-width: 992px) {
    height: 460px;
    max-width: 64rem;
    background: #fff;
  }

  @media (min-width: 1200px) {
    max-width: 100%;
    height: 460px;
  }

  @media (min-width: 1400px) {
    width: 100%;
  }
`;

export const StyledImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) =>
    props.imageDesktop || "default-desktop-image.jpg"});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
`;

const NewService = styled.div`
  position: absolute;
  top: 70%;
  left: 0;
  width: 234px;
  height: 50px;
  background-image: url(${BgNewService});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 768px) {
    top: 60%;
  }
`;
const NewServiceTxt = styled.span`
  color: #7b8288;
  font-size: 22px;
  font-weight: 500;
  margin-left: 10px;
`;
const BoxPagoLinea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: auto;
  gap: 5px;
  button {
    border: 2px solid #1aa687;
    background-color: #fff;
    color: #1aa687;
    padding: 1px 10px;
    border-radius: 10px;
    outline: none;
    text-align: center;
    font-weight: 700;
    align-self: flex-start;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      border: 2px solid transparent;
      background: #1aa687;
      color: #fff;
    }
    @media (min-width: 992px) {
      font-size: 17px;
    }

    @media (min-width: 1200px) {
      font-size: 22px;
      padding: 3px 40px;
    }

    @media (min-width: 1400px) {
    }
  }
`;

const BtnInfo = styled.button`
  display: inline-block;
  text-align: center;
  //padding: 1px 10px;
  border-radius: 10px;
  text-decoration: none;
  font-weight: 700;
  align-self: flex-start;
  font-size: 14px;
  transition: all 0.2s ease;
  background-color: #f9ad62;
  border: 1px solid transparent;
  color: #fff;
  outline: none;
  &:hover {
    color: #f9ad62;
    border: 1px solid #f9ad62;
    background: #fff;
  }

  @media (min-width: 992px) {
    font-size: 17px;
  }

  @media (min-width: 1200px) {
    font-size: 22px;
    //padding: 3px 40px;
  }

  @media (min-width: 1400px) {
  }

  span {
    display: block;
    line-height: 20px;
  }
`;

const PanelHigado = () => {
  const [local, setLocal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const { items } = useSelector((state) => state.shoppingCart);
  const [study, setStudy] = useState({
    name: "",
    description: "",
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });
  const [width, setWidth] = useState(window.innerWidth);
  let isMobile = width <= 768;
  const studyId = "riesgo-de-higado-graso-no-alcoholico";
  useEffect(() => {
    getInfo();
  }, []);

  const handleClick = () => {
    const phoneNumber = "2212311313";
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=Hola, quiero más información sobre el estudio ${study.title}`;
    window.open(url, "_blank");
  };
  const getInfo = async () => {
    try {
      let request = axios.get(
        `https://lpqsystem.com/obtener-estudio/${studyId}/${
          idClienteLabopat ? `${idClienteLabopat}` : "0"
        }`
      );
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.promo_price,
        oldPrice: response.data.normal_price,
      });
      if (response.data.categoria == 6) {
        setLocal(true);
      }
      if (response.data.categoria == 9) {
        setLocal(true);
      }
    } catch (error) {
      console.log(error);
      history.push("/not-found");
    }
  };

  const payStudy = () => {
    const exists = studyInCart(study, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
    } else {
      dispatch(handleAddItemToCart(study));
      history.push("/checkoutp");
    }
  };

  const navegarAEstudio = () => {
    history.push("/estudio/riesgo-de-higado-graso-no-alcoholico");
  };

  return (
    <>
      <Container backgroundColor="#DBEBFA">
        <Row style={{ height: "100%" }}>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={5}
            xxl={5}
            style={{ margin: "0", padding: "0", position: "relative" }}
          >
            <img
              className="mt-4 mt-lg-0 d-block d-lg-none"
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
              src={imageMobile}
              alt="panel-gastrointestinal "
            />

            <StyledImage
              className="d-none d-lg-block"
              imageDesktop={imageDesktop}
            />
            <NewService>
              <NewServiceTxt style={{ color: "#fff" }}>
                Nueva prueba
              </NewServiceTxt>
            </NewService>
          </Col>

          <Col
            className="bg-white"
            xs={12}
            sm={12}
            md={12}
            lg={8}
            xl={7}
            xxl={7}
            style={{ margin: "0", padding: "0" }}
          >
            <CustomBox>
              <PanelContent>
                <BoxTitle
                  className=""
                  bgImage={bgImage}
                  bgImageDesk={bgImageDesk}
                  textColor="#fff"
                >
                  <h1 onClick={navegarAEstudio} style={{ cursor: "pointer" }}>
                    Riesgo de higado graso <br />
                    no alcohólico
                  </h1>
                </BoxTitle>

                <BoxPadding>
                  <Paragraph>
                    Analizamos el gen PNPLA3 para estratificar el riesgo de esta{" "}
                    <br />
                    enfermedad y ayudar a personalizar el tratamiento.
                  </Paragraph>

                  <ContainerPricing>
                    <BoxPagoLinea>
                      <PriceLinea>
                        $2,500.00 <small>MXN</small>
                      </PriceLinea>
                      <button onClick={() => payStudy()}>Agendar Toma</button>
                      <PriceDetails>
                        Precio en sucursal y domicilio
                      </PriceDetails>
                    </BoxPagoLinea>
                    <BoxPagoSucursal>
                      <PriceLinea>
                        $2,250.00 <small>MXN</small>
                      </PriceLinea>
                      <BtnPagarAhora onClick={() => payStudy()}>
                        Paga ahora -10%
                      </BtnPagarAhora>
                      <PriceDetails>Precio en línea</PriceDetails>
                    </BoxPagoSucursal>
                  </ContainerPricing>
                </BoxPadding>
                <BoxBgBottom bgImageBottom={bgImageBottom}>
                  <span style={{ color: "#7B8288" }}>
                    *Disponible en todas las sucursales.
                  </span>
                </BoxBgBottom>
              </PanelContent>
            </CustomBox>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PanelHigado;
